import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import { TextField } from '@mui/material';
import { Backdrop, Box, Paper, stackLayout, List, Grid, Modal, Fade } from '@mui/material';

import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import { Card,CardActionArea,CardContent,CardMedia,Typography } from '@mui/material';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import i18n from '../../i18n';

import axios from "../../axios.js";
import TokenService from '../../TokenService.js';
import LocalStorageTTL from '../../LocalStorageTTL.js';

import { useTranslation } from "react-i18next";

import './RentalTable.css';
import Logo from '../../logo.png';
import LogoRow from '../../components/logoRow.js';

import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick

import InputAdornment from '@mui/material/InputAdornment';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';

export default function RentalTable() {
  const { t } = useTranslation();
  const isTraditionalChinese = i18n.language == 'zh_hk';
  const token = TokenService.getToken();
  const dontShowRentalTable = LocalStorageTTL.getWithExpiry('dontShowRentalTable');
  const [room, setRoom] = useState();
  const { room_id } = useParams();
  //--Clear
  localStorage.removeItem('proceed_link');
  localStorage.removeItem('member_only');
  //--popup modal - Landing
  const modal_style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 380,
    textAlign: 'center',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    borderRadius: '24px',
    padding: '50px',
    boxShadow: 24,
    p: 4,
  };
  const [landingModal, setLandingModal] = React.useState(true);
  const openLandingModal = () => setLandingModal(true);
  const closeLandingModal = () => setLandingModal(false);
  //
  const closeAndRmbLandingModal = () => {
    LocalStorageTTL.setWithExpiry('dontShowRentalTable', 1);
    setLandingModal(false);
  };
  //--
  React.useEffect(() => {
    axios.get('', {
        params: {
          name: 'getRoomDetails',
          rid: room_id,
          headers: {
            // 'Authorization': 'Bearer ' + TokenService.getToken()
          },
        }
      })
    .then((response) => {
      if(response.status == 200){
        if(response.data.status == 200){
          console.log("getRoomDetails");
          console.log(response.data.room);
          console.log("-------------");
          setRoom(response.data.room);
        }
      }else{
        alert((i18n.language == 'zh_hk')?"錯誤":"错误");
      }
    });
  }, []);
  if(room){
    if(!token){
      return(
        <div className="app-wrapper">
          <div className="app-page">
            <Container>
              <Row>
                <LogoRow />
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <div className="app-rental-page-main">
                    <RentalTableBlock room={room} />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <div className="app-page-bottom">
                    <div className="app-bottom">
                      <div className="app-bottom-container">
                        <label className="app-logo-text">如需租用凌晨時段(00:00-09:00)，請<a className="app-logo-text-link" href="https://wa.me/+85296171444?text=Hello">Whatsapp</a> 聯絡</label>
                        <label className="app-logo-text">For renting (00:00-09:00), Please contact via <a className="app-logo-text-link" href="https://wa.me/+85296171444?text=Hello">Whatsapp</a></label>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={landingModal}
              onClose={closeLandingModal}
              closeAfterTransition
              slots={{ backdrop: Backdrop }}
              slotProps={{
                backdrop: {
                  timeout: 500,
                },
              }}
            >
              <Fade in={landingModal}>
                <Box sx={modal_style}>
                  <Typography id="transition-modal-title" variant="h6" component="h2">
                    租用流程 Steps
                  </Typography>
                  <Typography sx={{ mt: 2 }}>
                  從開始租用的時間區域點擊以選擇
                  <br/>
                  Click the required slot from Timetable
                  </Typography>
                  <Typography sx={{ mt: 2 }}>
                  選擇結束時間並點「租用」進入付款程序
                  <br/>Choose rental period & click 'Rental'
                  </Typography>
                  <br/>
                  <button type="button" className="modal-bottom-button black-button" onClick={closeLandingModal}>知道了 Got it</button>
                </Box>
              </Fade>
            </Modal>
          </div>
        </div>
      );
    }else{
      return(
        <div className="app-wrapper">
          <div className="app-page">
            <Container>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <LogoRow />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <div className="app-rental-page-main">
                    <RentalTableBlock room={room} />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <div className="app-page-bottom">
                    <div className="app-bottom">
                      <div className="app-bottom-container">
                        <label className="app-logo-text">如需租用凌晨時段(00:00-09:00)，請<a className="app-logo-text-link" href="https://wa.me/+85296171444?text=Hello">Whatsapp</a> 聯絡</label>
                        <label className="app-logo-text">For renting (00:00-09:00), Please contact via <a className="app-logo-text-link" href="https://wa.me/+85296171444?text=Hello">Whatsapp</a></label>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={dontShowRentalTable==1?false:landingModal}
              onClose={closeLandingModal}
              closeAfterTransition
              slots={{ backdrop: Backdrop }}
              slotProps={{
                backdrop: {
                  timeout: 500,
                },
              }}
            >
              <Fade in={landingModal}>
                <Box sx={modal_style}>
                  <Typography id="transition-modal-title" variant="h6" component="h2">
                    租用流程 Steps
                  </Typography>
                  <Typography sx={{ mt: 2 }}>
                    從開始租用的時間區域點擊以選擇
                    <br/>
                    Click the required slot from Timetable
                    </Typography>
                    <Typography sx={{ mt: 2 }}>
                    選擇結束時間並點「租用」進入付款程序
                    <br/>Choose rental period & click 'Rental'
                  </Typography>
                  <br/>
                  <button type="button" className="modal-bottom-button white-button" onClick={closeAndRmbLandingModal}>下次不再提示 Dont mention again</button>
                  <br/>
                  <button type="button" className="modal-bottom-button black-button" onClick={closeLandingModal}>知道了 Got it</button>
                </Box>
              </Fade>
            </Modal>
          </div>
        </div>
      );
    }
  }else{
    return(<h3></h3>);
  }
};
function RentalTableBlock(props){
  const { t } = useTranslation();
  const token = TokenService.getToken();
  const visitorName = LocalStorageTTL.getWithExpiry('visitorName');
  const visitorPhone = LocalStorageTTL.getWithExpiry('visitorPhone');
  const { room_id } = useParams();
  //--popup modal - Rental
  const modal_style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 380,
    textAlign: 'center',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    borderRadius: '24px',
    padding: '50px',
    boxShadow: 24,
    p: 4,
  };
  const [rentalModal, setRentalModal] = React.useState(false);
  const openRentalModal = (arg) => {
    const year = arg.dateStr.substring(0,4);
    const month = arg.dateStr.substring(5,7);
    const day = arg.dateStr.substring(8,10);
    setRentalDate(dayjs(new Date(year, month-1, day)));
    const hh = arg.date.toLocaleString('en-GB').substring(12,14);
    const mm = arg.date.toLocaleString('en-GB').substring(15,17);
    setRentalStartTime(dayjs().set('hour', hh).set('minute', mm).startOf('minute'));
    //--add an hour for default
    arg.date.setHours(arg.date.getHours()+1);
    const new_hh = arg.date.toLocaleString('en-GB').substring(12,14);
    const new_mm = arg.date.toLocaleString('en-GB').substring(15,17);
    setRentalEndTime(dayjs().set('hour', new_hh).set('minute', new_mm).startOf('minute'));
    LocalStorageTTL.setWithExpiry('rentalDayIndex', arg.date.getDay());
    //--display
    setRentalModal(true);
  }
  const closeRentalModal = () => setRentalModal(false);
  //
  const [alertModal, setAlertModal] = React.useState(false);
  const openAlertModal = () => setAlertModal(true);
  const closeAlertModal = () => setAlertModal(false);
  //
  const [validationModal, setValidationModal] = React.useState(false);
  const openValidationModal = () => setValidationModal(true);
  const closeValidationModal = () => setValidationModal(false);
  //
  const [chosenModal, setChosenModal] = React.useState(false);
  const openChosenModal = () => setChosenModal(true);
  const closeChosenModal = () => setChosenModal(false);
  //
  const [rentalDate, setRentalDate] = useState();
  const [rentalStartTime, setRentalStartTime] = useState();
  const [rentalEndTime, setRentalEndTime] = useState();
  const [rentalRemarks, setRentalRemarks] = useState("");
  //--
  const now = new Date();
  const handleDateClick = (arg) => { // bind with an arrow function
    //--Check preivous or future date
    if(arg.date.getTime()<now.getTime()){
      openAlertModal();
    }else{
      openRentalModal(arg);
    }
  }
  const rentalOnSubmit = () => {
    if(rentalStartTime==rentalEndTime){
      setValidationModal(true);
    }else{
      checkRentalTime();
    }
  }
  async function checkRentalTime() {
    try
    {
       axios.post('', {
        "name":"checkRentalTime",
        "param": {
            "room_id":room_id,
            "rentalDate":rentalDate.format('YYYY/MM/DD'),
            "rentalStartTime":rentalStartTime.format('HH:mm'),
            "rentalEndTime":rentalEndTime.format('HH:mm'),
            "rentalDayIndex":rentalDate.format('d')
        }
      })
      .then((response) => {
        console.log(response);
        if(response.status == 200){
          if(response.data.status == 200){
            //--SUCCESS
            LocalStorageTTL.setWithExpiry('rentalDate', rentalDate.format('YYYY/MM/DD'));
            LocalStorageTTL.setWithExpiry('rentalDayIndex', rentalDate.format('d'));
            LocalStorageTTL.setWithExpiry('rentalStartTime', rentalStartTime.format('HH:mm'));
            LocalStorageTTL.setWithExpiry('rentalEndTime', rentalEndTime.format('HH:mm'));
            LocalStorageTTL.setWithExpiry('rentalRemarks', rentalRemarks);
            LocalStorageTTL.setWithExpiry('rentalDuration', (rentalEndTime.format('H') - rentalStartTime.format('H'))+(rentalEndTime.format('mm') - rentalStartTime.format('mm'))/60);
            if(!token){
              LocalStorageTTL.setWithExpiry('proceed_link', ('/home/rent/'+room_id)+'/payment');
            }else{
              localStorage.removeItem('proceed_link');
            }
            LocalStorageTTL.setWithExpiry('proceed_rentalTable_initialDate', rentalDate.format('YYYY-MM-DD'));
            window.location.href=('/home/rent/'+room_id)+'/payment';
          }else{
            openChosenModal();
            console.log(response.data.message);
          }
        }else{
          alert((i18n.language == 'zh_hk')?"連接出現問題！請聯絡相關技術人員！":"连接出现问题！请联络相关技术人员！");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    }
    catch (error)
    {
      alert(error);
    }
  };
  const startTimeOnChange = (newValue) => {
    setRentalStartTime(newValue);
    console.log(newValue.format('H'));
    // console.log(rentalStartTime.format('H')+1);
    console.log(newValue.format('m'));
    setRentalEndTime(dayjs().set('hour', Number(newValue.format('H'))+2).set('minute', newValue.format('m')).startOf('minute'));
    setRentalEndTime(newValue);
    console.log(rentalEndTime);
  }
  const initialDate = LocalStorageTTL.getWithExpiry('proceed_rentalTable_initialDate')==""?dayjs().format('YYYY-MM-DD'):LocalStorageTTL.getWithExpiry('proceed_rentalTable_initialDate');
  const display = (props) => {
      const { room } = props;
      LocalStorageTTL.setWithExpiry('rentalRoom', room.room_name);
      LocalStorageTTL.setWithExpiry('rentalRoomPrice', room.room_price);
      if(room !== undefined){
        return(
          <>
            <FullCalendar
              plugins={[ timeGridPlugin, interactionPlugin ]}
              events={room.classes}
              initialDate={initialDate}
              initialView="timeGridWeek"
              scrollTime="16:00:00"
              slotMinTime="9:00:00"
              slotMaxTime="24:00:00"
              slotLabelFormat= {{
                hour: '2-digit',
                minute: '2-digit',
                hour12:false
              }}
              eventTimeFormat= {{ // like '14:30:00'
                hour: '2-digit',
                minute: '2-digit',
                hour12:false
              }}
              weekends={true}
              customButtons={{
                  backButton: {
                    text: '返回 Back',
                    click: function() {
                      window.location.href='/home/rent'
                    },
                  },
                  titleButton: {
                    text: room.room_name,
                  },
                }}
              headerToolbar={{
                start: 'prev,next', // will normally be on the left. if RTL, will be on the right
                center: 'title',
                end: 'today', // will normally be on the right. if RTL, will be on the left
              }}
              allDayContent={{
                html:"全日"
              }}
              buttonText={{
                today:    '今天 Today',
                month:    'month',
                week:     'week',
                day:      'day',
                list:     'list'
              }}
              dateClick={handleDateClick}
            />
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={rentalModal}
              onClose={closeRentalModal}
              closeAfterTransition
              slots={{ backdrop: Backdrop }}
              slotProps={{
                backdrop: {
                  timeout: 500,
                },
              }}
            >
              <Fade in={rentalModal}>
                <Box sx={modal_style}>
                  <Typography id="transition-modal-title" variant="h6" component="h2">
                    {room.room_name}
                  </Typography>
                  <form className="app-rental-page-form" noValidate>
                    <Typography sx={{ mt: 2 }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                          <DatePicker
                            id="rental_date"
                            label="日期 Date"
                            value={rentalDate}
                            onChange={(date) => {setRentalDate(date)}}
                            views={['year', 'month', 'day']}
                            format="YYYY-MM-DD"
                            disablePast
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Typography>
                    <Typography sx={{ mt: 2 }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['TimePicker', 'TimePicker']}>
                          <TimePicker
                            id="rental_start_time"
                            label="開始時間 Start time"
                            ampm={false}
                            minutesStep={30}
                            timeStep={{ hours: 1, minutes: 30, seconds: 5 }}
                            value={rentalStartTime}
                            onChange={(newValue) => startTimeOnChange(newValue)}
                            autoFocus={true}
                            views={['hours', 'minutes']}
                            minTime={dayjs().set('hour', 9).startOf('hour')}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                      <label className="app-logo-text">時間為24小時制，請留下正確的時間</label>
                    </Typography>
                    <Typography sx={{ mt: 2 }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['TimePicker', 'TimePicker']}>
                          <TimePicker
                            id="rental_end_time"
                            label="結束時間 End time"
                            ampm={false}
                            minutesStep={30}
                            timeStep={{ hours: 1, minutes: 30, seconds: 5 }}
                            value={rentalEndTime}
                            onChange={(newValue) => setRentalEndTime(newValue)}
                            views={['hours', 'minutes']}
                            minTime={rentalStartTime}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                      <label className="app-logo-text">Time format is 24hrs, Please choose the right time.</label>
                    </Typography>
                    <Typography sx={{ mt: 2 }}>
                      <TextField
                        id=""
                        sx={{
                          "& .MuiInputBase-root": {
                            color: 'black',
                            width: '100%'
                          },
                          "& .MuiSvgIcon-root":{
                            color: 'black',
                          },
                          "& .MuiFormLabel-root":{
                            color: 'black',
                          },
                          "& .MuiInputBase-input:after":{
                            color: 'black',
                          },
                          "& .Mui-focused":{
                            color: 'black',
                          },
                          "& .Mui-focused:after":{
                            borderBottom: "1px solid white"
                          }
                         }}
                        placeholder="租用說明 Remarks"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <BadgeOutlinedIcon />
                            </InputAdornment>
                          ),
                        }}
                        variant="standard"
                        color="warning"
                        focused
                        fullwidth
                        value={rentalRemarks}
                        onChange={e => setRentalRemarks(e.target.value)}
                      />
                    </Typography>
                  </form>
                  <br/>
                  <button type="button" className="modal-bottom-button white-button" onClick={closeRentalModal}>返回 Back</button>
                  <br/>
                  <button type="button" className="modal-bottom-button black-button" onClick={() => rentalOnSubmit()}>租用 Rental</button>
                </Box>
              </Fade>
            </Modal>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={alertModal}
              onClose={closeAlertModal}
              closeAfterTransition
              slots={{ backdrop: Backdrop }}
              slotProps={{
                backdrop: {
                  timeout: 500,
                },
              }}
            >
              <Fade in={alertModal}>
                <Box sx={modal_style}>
                  <Typography id="transition-modal-title" variant="h6" component="h2">
                    (!)
                  </Typography>
                  <Typography sx={{ mt: 2 }}>
                  請選擇將來的時段
                  </Typography>
                  <Typography sx={{ mt: 2 }}>
                  Please choose the proper time
                  </Typography>
                  <br/>
                  <button type="button" className="modal-bottom-button black-button" onClick={closeAlertModal}>知道了 Got it</button>
                </Box>
              </Fade>
            </Modal>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={validationModal}
              onClose={closeValidationModal}
              closeAfterTransition
              slots={{ backdrop: Backdrop }}
              slotProps={{
                backdrop: {
                  timeout: 500,
                },
              }}
            >
              <Fade in={validationModal}>
                <Box sx={modal_style}>
                  <Typography id="transition-modal-title" variant="h6" component="h2">
                    (!)
                  </Typography>
                  <Typography sx={{ mt: 2 }}>
                  請選擇正確的時段
                  </Typography>
                  <Typography sx={{ mt: 2 }}>
                  Please choose the proper time
                  </Typography>
                  <br/>
                  <button type="button" className="modal-bottom-button black-button" onClick={closeValidationModal}>知道了 Got it</button>
                </Box>
              </Fade>
            </Modal>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={chosenModal}
              onClose={closeChosenModal}
              closeAfterTransition
              slots={{ backdrop: Backdrop }}
              slotProps={{
                backdrop: {
                  timeout: 500,
                },
              }}
            >
              <Fade in={chosenModal}>
                <Box sx={modal_style}>
                  <Typography id="transition-modal-title" variant="h6" component="h2">
                    (!)
                  </Typography>
                  <Typography sx={{ mt: 2 }}>
                  該時段已被租用
                  </Typography>
                  <Typography sx={{ mt: 2 }}>
                  The requested timeslot has been rented
                  </Typography>
                  <br/>
                  <button type="button" className="modal-bottom-button black-button" onClick={closeChosenModal}>知道了 Got it</button>
                </Box>
              </Fade>
            </Modal>
          </>
        );
      }else{
        return(<h3></h3>);
      }
    }
    return(
      <>
        {display(props)}
      </>
    );
}
