import React, { useState } from 'react';
import { useParams } from "react-router-dom";

import { QRCode } from 'react-qrcode-logo';

import { ImageBackground , Dimensions, SafeAreaView, ScrollView, StatusBar ,StyleSheet} from "react-native";
import { Paper, stackLayout,List, Grid } from '@mui/material';

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import dayjs from 'dayjs';

import { Card,CardActionArea,CardContent,CardMedia,Typography } from '@mui/material';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import i18n from '../../i18n';

import axios from "../../axios.js";
import TokenService from '../../TokenService.js';
import LocalStorageTTL from '../../LocalStorageTTL.js';

import { useTranslation } from "react-i18next";

import './Profile.css';
import Logo from '../../logo.png';
import SampleQRcode from '../../img/qrcode_coast.png';
import LogoBackground from '../../img/Watermark_Square.png';

import TokenIcon from '../../img/icon-DancingTicket.png';

export default function Profile() {
  const { t } = useTranslation();
  const isTraditionalChinese = i18n.language == 'zh_hk';
  const token = TokenService.getToken();
  const [user, setUser] = useState();
  const { mode } = useParams();
  const [showQR, setShowQR] = React.useState(false);
  const yesQR = () => setShowQR(true);
  const noQR = () => setShowQR(false);

  const styles = StyleSheet.create({
    container: {
      height: '100%'
    }
  });

  //--popup modal
  const handleSubmit = async e => {
    e.preventDefault();
    LocalStorageTTL.setWithExpiry('TokenPayment_token_num', 12);
    LocalStorageTTL.setWithExpiry('TokenPayment_price', 1380);
    window.location.href=('/home/account/payment/card')
  };
  //--
  React.useEffect(() => {
    axios.get('', {
        params: {
          name: 'getUserDetails',
          mode: mode,
          headers: {
            'Authorization': 'Bearer ' + TokenService.getToken()
          },
        }
      })
    .then((response) => {
      console.log(response);
      if(response.status == 200){
        if(response.data.status == 200){
          console.log("getUserDetails");
          console.log(response.data);
          console.log("-------------");
          setUser(response.data.user);
        }
      }else{
        alert((i18n.language == 'zh_hk')?"錯誤":"错误");
      }
    });
  }, []);
  if(user){
    LocalStorageTTL.setWithExpiry('user_uid', user.uid);
    LocalStorageTTL.setWithExpiry('user_name', user.username);
    LocalStorageTTL.setWithExpiry('user_full_name', user.last_name);
    LocalStorageTTL.setWithExpiry('user_contact',user.contact);
    LocalStorageTTL.setWithExpiry('user_email',user.email);
    return(
      <div className="app-wrapper">
        <div className="app-page">
          <Container>
            <Row>
              <div className="app-logo">
                <img className="app-logo-img" src={Logo} onClick={event =>  window.location.href='/home'}/>
                <label className="app-logo-text">Professional Training, Branding, Business Consulting</label>
                <a className="app-logo-text" href="mailto:info@nsayn.com.hk">info@nsayn.com.hk </a>
                <label className="app-logo-text"><a className="app-logo-text-link" href="https://wa.me/+85292525890?text=查詢最新課程">Whatsapp: 92525890</a></label>
              </div>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={4}>
                <div className="app-page-top">
                  <div className="app-top">
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={8}>
                <div className="app-page-top desktop-only">
                  <div className="app-top">
                      <button type="submit" className="app-profile-container-show-booking" onClick={event =>  window.location.href='/home/account/bookings'}>預約課堂紀錄 Booking Records</button>
                    </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={4}>
                <Card
                  style={{
                    marginTop: "20px",
                    marginBottom: "20px",
                    width: '100%',
                    borderRadius: '28px',
                    backgroundColor: '#E6EDF3',
                    backgroundImage: `url(${LogoBackground})`,
                    backgroundPosition:'center'
                  }}
                >
                  <CardContent>
                    <Typography
                      sx={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 1,
                      }}
                      style={{color:"black",fontWeight: "bold", whiteSpace:'pre-wrap'}}
                      variant="body1"
                    >
                      <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontSize: '24px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        marginBottom: '8px'
                      }}>
                        N'SayN's Member
                      </div>
                      <div className="app-profile-text-block">
                        {
                          showQR? (
                            <i onClick={noQR}><QRCode ecLevel="Q" logoImage={user.image_url} size="240" value={user.uid+''}/></i>
                          ) : (
                            <img className="app-profile-icon app-logo-img" src={user.image_url} onClick={yesQR}/>
                          )
                        }
                      </div>
                      <hr className="bold-horizen-line"/>
                      <div className="app-profile-text-block">
                        <div className="app-profile-text-block-left">
                          <div className="app-profile-text-block-top">
                            會員編號
                          </div>
                          <div className="app-profile-text-block-bottom">
                            Member No.
                          </div>
                        </div>
                        <div className="app-profile-text-block-right">
                          {user.user_number}
                        </div>
                      </div>
                      <div className="app-profile-text-block">
                        <div className="app-profile-text-block-left">
                          <div className="app-profile-text-block-top">
                            登入名稱
                          </div>
                          <div className="app-profile-text-block-bottom">
                            Login Name
                          </div>
                        </div>
                        <div className="app-profile-text-block-right">
                          {user.username}
                        </div>
                      </div>
                      <div className="app-profile-text-block">
                        <div className="app-profile-text-block-left">
                          <div className="app-profile-text-block-top">
                            姓名
                          </div>
                          <div className="app-profile-text-block-bottom">
                            Name
                          </div>
                        </div>
                        <div className="app-profile-text-block-right">
                          {user.last_name}
                        </div>
                      </div>
                      <div className="app-profile-text-block">
                        <div className="app-profile-text-block-left">
                          <div className="app-profile-text-block-top">
                            電話號碼
                          </div>
                          <div className="app-profile-text-block-bottom">
                            Contact No.
                          </div>
                        </div>
                        <div className="app-profile-text-block-right">
                          {user.contact}
                        </div>
                      </div>
                      <div className="app-profile-text-block">
                        <div className="app-profile-text-block-left">
                          <div className="app-profile-text-block-top">
                            電郵地址
                          </div>
                          <div className="app-profile-text-block-bottom">
                            Email
                          </div>
                        </div>
                        <div className="app-profile-text-block-right">
                          {user.email}
                        </div>
                      </div>
                    </Typography>
                    <div className="app-profile-container">
                      <button type="submit" className="app-profile-container-edit" onClick={event =>  window.location.href='/home/account/edit'}>修改個人資料 Edit Personal Info</button>
                    </div>
                  </CardContent>
                </Card>
              </Col>
              <Col xs={12} sm={12} md={12} lg={8}>
                <div className="app-page-top mobile-only">
                  <div className="app-top">
                    <div className="app-profile-container">
                      <button type="submit" className="app-profile-container-show-booking" onClick={event =>  window.location.href='/home/account/bookings'}>預約課堂紀錄 Booking Records</button>
                    </div>
                  </div>
                </div>
                <div className="profile-record-list">
                  <SafeAreaView style={styles.container}>
                    <ScrollView vertical={true}>
                      <Grid container rowSpacing={1} columnSpacing={1}>
                        <DataRecordsBlock profile_data={user.profile_data} />
                      </Grid>
                    </ScrollView>
                  </SafeAreaView>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }else{
    return(<h3></h3>);
  }
};
function DataRecordsBlock(props){
  const { mode } = useParams();
  const display = (props) => {
      const { profile_data } = props;
      console.log(profile_data);
      if(profile_data !== undefined){
        if(mode=="rentals"){
          return(
            <>
              {
                profile_data.map( (item, i) => <RentalRecordItem key={i} item={item} /> )
              }
            </>
          );
        }else if(mode=="bookings"){
          return(
            <>
              {
                profile_data.map( (item, i) => <BookingRecordItem key={i} item={item} /> )
              }
            </>
          );
        }else if(mode=="tokens"){
          return(
            <>
              {
                profile_data.map( (item, i) => <TokenRecordItem key={i} item={item} /> )
              }
            </>
          );
        }else{
          //
        }
      }else{
        return(<h3></h3>);
      }
    }
    return(
      <>
        {display(props)}
      </>
    );
}
function RentalRecordItem(props)
{
  const { item } = props;
  const recordOnClick = () =>{
    LocalStorageTTL.setWithExpiry('selectedRecord', JSON.stringify(item));
    window.location.href=('/home/account/record/rental');
  }
  //--Set day index
  var display_day = "Sun";
  if(item.day_index == 1){
    display_day = "Mon";
  }else if (item.day_index == 2) {
    display_day = "Tue";
  }else if (item.day_index == 3) {
    display_day = "Wed";
  }else if (item.day_index == 4) {
    display_day = "Thu";
  }else if (item.day_index == 5) {
    display_day = "Fri";
  }else if (item.day_index == 6) {
    display_day = "Sat";
  }
  //--Set status display
  var display_status = "";
  if(item.status == "reserve"){
    display_status = "成功";
  }else if (item.status == "cancel") {
    display_status = "已取消";
  }
  return (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <Card
        style={{
          width: '100%',
          borderRadius: '28px',
          backgroundColor: '#E6EDF3',
          backgroundImage: `url(${LogoBackground})`,
          backgroundPosition:'center',
          cursor:'pointer'
        }}
        onClick={recordOnClick}
      >
        <CardContent>
          <Typography
            sx={{
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 1,
            }}
            style={{color:"black",fontWeight: "bold", whiteSpace:'pre-wrap'}}
            variant="body1"
          >
            <div className="app-profile-text-block">
              <div className="app-profile-record-block-left">
                {item.starting_date.substring(0,4)} / {item.starting_date.substring(5,7)} / {item.starting_date.substring(8,10)}
              </div>
              <div className="app-profile-record-block-right">
                {item.room_name}
              </div>
            </div>
            <div className="app-profile-text-block">
              <div className="app-profile-record-block-left">
                {display_day} / {item.start_time.substring(0,2)}:{item.start_time.substring(3,5)} - {item.end_time.substring(0,2)}:{item.end_time.substring(3,5)}
              </div>
              <div className="app-profile-record-block-right">
                {item.payment_method}
                {
                  item.payment_method=="Token"?
                    " "+item.room_token + "張"
                    :
                    " HKD$"+(item.price)
                }
              </div>
            </div>
            <div className="app-profile-text-block-bottom">({display_status})</div>
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  )
}
function BookingRecordItem(props)
{
  const { item } = props;
  const recordOnClick = () =>{
    LocalStorageTTL.setWithExpiry('selectedRecord', JSON.stringify(item));
    window.location.href=('/home/account/record/booking');
  }
  //--Set day index
  var display_day = "Sun";
  if(item.day_index == 1){
    display_day = "Mon";
  }else if (item.day_index == 2) {
    display_day = "Tue";
  }else if (item.day_index == 3) {
    display_day = "Wed";
  }else if (item.day_index == 4) {
    display_day = "Thu";
  }else if (item.day_index == 5) {
    display_day = "Fri";
  }else if (item.day_index == 6) {
    display_day = "Sat";
  }
  //--Set status display
  var display_status = "";
  if(item.status == "reserve"){
    display_status = "成功";
  }else if (item.status == "cancel") {
    display_status = "已取消";
  }
  return (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <Card
        style={{
          width: '100%',
          borderRadius: '28px',
          backgroundColor: '#E6EDF3',
          backgroundImage: `url(${LogoBackground})`,
          backgroundPosition:'center',
          cursor:'pointer'
        }}
        onClick={recordOnClick}
      >
        <CardContent>
          <Typography
            sx={{
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 1,
            }}
            style={{color:"black",fontWeight: "bold", whiteSpace:'pre-wrap'}}
            variant="body1"
          >
            <div className="app-profile-text-block">
              <div className="app-profile-record-block-left">
                {item.class_date.substring(0,4)} / {item.class_date.substring(5,7)} / {item.class_date.substring(8,10)}
              </div>
              <div className="app-profile-record-block-right">
                {item.class_details}
              </div>
            </div>
            <div className="app-profile-text-block">
              <div className="app-profile-record-block-left">
                {display_day} / {item.class_date.substring(11,16)} - {item.end_time.substring(0,5)}
              </div>
              <div className="app-profile-record-block-right">
                {item.payment_method}
                {
                  item.payment_method=="Token"?
                    " "+item.token + "張"
                    :
                    // " HKD$"+(item.price)
                    " HKD$"+(item.lesson_price)
                }
              </div>
            </div>
            <div className="app-profile-text-block-bottom">({display_status})</div>
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  )
}
function TokenRecordItem(props)
{
  const { item } = props;
  const recordOnClick = () =>{
    LocalStorageTTL.setWithExpiry('selectedRecord', JSON.stringify(item));
    window.location.href=('/home/account/record/token');
  }
  //--Set status display
  var display_status = "";
  if(item.status == "add"){
    display_status = "成功";
  }else if (item.status == "cancel") {
    display_status = "已取消";
  }
  return (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <Card
        style={{
          width: '100%',
          borderRadius: '28px',
          backgroundColor: '#E6EDF3',
          backgroundImage: `url(${LogoBackground})`,
          backgroundPosition:'center',
          cursor:'pointer'
        }}
        onClick={recordOnClick}
      >
        <CardContent>
          <Typography
            sx={{
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 1,
            }}
            style={{color:"black",fontWeight: "bold", whiteSpace:'pre-wrap'}}
            variant="body1"
          >
            <div className="app-profile-text-block">
              <div className="app-profile-record-block-left">
                <img className="img-tokenIcon" src={TokenIcon}/> X {item.token_num}
              </div>
              <div className="app-profile-record-block-right">
                {item.payment_method} HKD${item.price}
              </div>
            </div>
            <div className="app-profile-text-block">
              <div className="app-profile-record-block-left">
                到期日 Expiration Day
              </div>
              <div className="app-profile-record-block-right">
                {item.expiry_date.substring(0,10)}
              </div>
            </div>
            <div className="app-profile-text-block-bottom">({display_status})</div>
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  )
}
