import React, { useState, createRef } from 'react';
import { useParams } from "react-router-dom";
import { ImageBackground , Dimensions, SafeAreaView, ScrollView, StatusBar ,StyleSheet} from "react-native";
import { Paper, stackLayout,List, Grid } from '@mui/material';

import { useScreenshot, createFileName } from "use-react-screenshot";

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';



import { Card,CardActionArea,CardContent,CardMedia,Typography } from '@mui/material';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import i18n from '../../i18n';

import axios from "../../axios.js";
import TokenService from '../../TokenService.js';
import LocalStorageTTL from '../../LocalStorageTTL.js';

import { useTranslation } from "react-i18next";
import './BookingPaymentSuccess.css';
import Logo from '../../logo.png';
import LogoBackground from '../../img/Watermark_Square.png';
import PaymentSuccessImg from '../../img/check-circle.png';
import LogoRow from '../../components/logoRow.js';

export default function BookingPaymentSuccess() {
  const { t } = useTranslation();
  const isTraditionalChinese = i18n.language == 'zh_hk';
  const [booking, setBooking] = useState();
  const { booking_id } = useParams();
  const booking_detail = JSON.parse(LocalStorageTTL.getWithExpiry('booking_detail'));
  localStorage.removeItem('proceed_bookTable_initialDate');
  //--
  console.log("getSessionValue");
  console.log(booking_detail);
  if(booking_detail){
    //--Set day index
    var display_day = "Sun";
    if(booking_detail.day_index == 1){
      display_day = "Mon";
    }else if (booking_detail.day_index == 2) {
      display_day = "Tue";
    }else if (booking_detail.day_index == 3) {
      display_day = "Wed";
    }else if (booking_detail.day_index == 4) {
      display_day = "Thu";
    }else if (booking_detail.day_index == 5) {
      display_day = "Fri";
    }else if (booking_detail.day_index == 6) {
      display_day = "Sat";
    }
    return(
      <div className="app-wrapper">
        <div className="app-page ">
          <Container>
            <Row>
              <LogoRow />
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={2}>
              </Col>
              <Col xs={12} sm={12} md={12} lg={8}>
                <Card
                  style={{
                    width: '100%',
                    borderRadius: '28px',
                    backgroundColor: '#E6EDF3',
                    backgroundImage: `url(${LogoBackground})`,
                    backgroundPosition:'center'
                  }}
                >
                  <CardContent>
                    <div className="app-payment-success-container">
                      <img className="app-payment-success-img" src={PaymentSuccessImg}/>
                      <div className="app-payment-success-text">
                        預約成功！
                      </div>
                      <div className="app-payment-success-text">
                        Book success!
                      </div>
                    </div>
                    <hr/>
                    <Typography
                      sx={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 1,
                      }}
                      style={{color:"black",fontWeight: "bold", whiteSpace:'pre-wrap'}}
                      variant="body1"
                    >
                      <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontSize: '18px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}>
                        {booking_detail.class_date.substring(0,10)}
                      </div>
                      <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontSize: '18px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}>
                        {display_day} / {booking_detail.class_date.substring(11,16)} - {booking_detail.end_time.substring(0,5)}
                      </div>
                      <hr/>
                      <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}>
                        付款方法 Payment Method - {booking_detail.payment_method}
                      </div>
                      <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}>
                        編號 No. - {booking_detail.id}
                      </div>
                      <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}>
                        {(booking_detail.class_details)?("預約說明 Remarks - "+booking_detail.class_details):""}
                      </div>
                      <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}>
                        <img className="app-logo-img" src={booking_detail.image_url}/>
                      </div>
                    </Typography>
                  </CardContent>
                </Card>
                <label className="app-logo-text">*請截圖並於使用場地時顯示予工作人員</label>
                <label className="app-logo-text">*Please screen-capture and give it to our staff</label>
              </Col>
              <Col xs={12} sm={12} md={12} lg={2}>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={2}>
              </Col>
              <Col xs={12} sm={12} md={12} lg={8}>
                <div className="app-page-bottom">
                  <div className="app-bottom">
                    <div className="app-bottom-container">
                      <button type="button" className="app-bottom-container-back" onClick={event =>  window.location.href='/home'}>返回主頁 Back Home</button>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={2}>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }else{
    return(<h3></h3>);
  }
};
