import React, { useState } from 'react';
import { ImageBackground , Dimensions} from "react-native";
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { Box, Paper, stackLayout, List, Grid } from '@mui/material';

import { Card,CardActionArea,CardContent,CardMedia,Typography } from '@mui/material';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import i18n from '../i18n';

import axios from "../axios.js";
import TokenService from '../TokenService.js';
import LocalStorageTTL from '../LocalStorageTTL.js';

import { motion, useViewportScroll, useTransform, useMotionValue } from "framer-motion";

import { useTranslation } from "react-i18next";

import './Home.css';
import Logo from '../logo.png';
import LogoRow from '../components/logoRow.js';

export default function Home() {
  const { t } = useTranslation();
  const isTraditionalChinese = i18n.language == 'zh_hk';
  const token = TokenService.getToken();
  const visitorName = LocalStorageTTL.getWithExpiry('visitorName');
  const [profiles, setProfiles] = useState();

  const logout  = () => {
    localStorage.clear();
    window.location.reload();
  }
  const logoutVisitor  = () => {
    localStorage.clear();
    window.location.reload();
  }
  const onClickMyAccount = (role) => {
    if(role=="member"){//--Role: MEMBER
      window.location.href='/home/account/bookings';
    }else{
      if (role=="visitor_with_name") {//--Role: VISTOR /w NAME
        window.location.href='/home/register';
      }else{//--Role: VISTOR
        LocalStorageTTL.setWithExpiry('member_only', true);
        window.location.href='/home/login';
      }
    }
  }
  //--Clear
  localStorage.removeItem('proceed_link');
  localStorage.removeItem('proceed_rentalTable_initialDate',"");
  localStorage.removeItem('proceed_bookTable_initialDate',"");
  //--
  React.useEffect(() => {
    if(token&&token!="visitor_token"){
      axios.get('', {
          params: {
            name: 'getUserProfile',
            headers: {
              'Authorization': 'Bearer ' + TokenService.getToken()
            },
          }
        })
      .then((response) => {
        console.log(response);
        if(response.status == 200){
          if(response.data.status == 200){
            console.log("getUserProfile");
            console.log(response.data);
            console.log("-------------");
            setProfiles(response.data);
          }
        }else{
          alert((i18n.language == 'zh_hk')?"錯誤":"错误");
        }
      });
    }
  }, []);
  if(profiles){
    return(
      <div className="app-wrapper">
        <div className="app-page ">
          <Container>
            <Row>
              <LogoRow />
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="app-home-welcome-text">
                  <p>歡迎 Welcome</p>
                  <p>
                    <label className="app-home-username"> {profiles.user.username}</label>
                  </p>
                </div>
              </Col>
              <Col xs={0} sm={0} md={0} lg={2}>
              </Col>
              <Col xs={12} sm={12} md={12} lg={8}>
                <div className="home-button-container">
                  <button type="submit" className="home-button" onClick={event =>  window.location.href='/home/book'}>預約課堂 Lesson Bookings</button>
                </div>
                <div className="home-button-container">
                  <button type="submit" className="home-button" onClick={event =>  onClickMyAccount("member")}>我的帳戶 My Account</button>
                </div>
                <div className="home-button-container">
                  <button type="submit" className="home-button logout-button" onClick={logout}>登出 Logout</button>
                </div>
              </Col>
              <Col xs={0} sm={0} md={0} lg={2}>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }else{
    if(visitorName){
      return(
        <div className="app-wrapper">
          <div className="app-page ">
            <Container>
              <Row>
                <LogoRow />
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <div className="app-home-welcome-text">
                    <p>歡迎 Welcome</p>
                    <p>
                      <label className="app-home-username"> {visitorName}</label>
                    </p>
                  </div>
                </Col>
                <Col xs={0} sm={0} md={0} lg={2}>
                </Col>
                <Col xs={12} sm={12} md={12} lg={8}>
                  <div className="home-button-container">
                    <button type="submit" className="home-button" onClick={event =>  window.location.href='/home/book'}>預約課堂 Lesson Bookings</button>
                  </div>
                  <div className="home-button-container">
                    <button type="submit" className="home-button" onClick={event =>  onClickMyAccount("visitor_with_name")}>
                      <div>我的帳戶 My Account</div>
                      <div className="home-button-container-hint">註冊 Register</div>
                    </button>
                  </div>
                  <div className="home-button-container">
                    <button type="submit" className="home-button logout-button" onClick={logoutVisitor}>清除訪客紀錄 Clear Visitor Record</button>
                  </div>
                </Col>
                <Col xs={0} sm={0} md={0} lg={2}>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      );
    }else{
      return(
        <div className="app-wrapper">
          <div className="app-page ">
            <Container>
              <Row>
                <LogoRow />
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <div className="app-home-welcome-text">
                    <p>歡迎 Welcome</p>
                  </div>
                </Col>
                <Col xs={0} sm={0} md={0} lg={2}>
                </Col>
                <Col xs={12} sm={12} md={12} lg={8}>
                  <div className="home-button-container">
                    <button type="submit" className="home-button" onClick={event =>  window.location.href='/home/book'}>預約課堂 Lesson Bookings</button>
                  </div>
                  <div className="home-button-container">
                    <button type="submit" className="home-button" onClick={event =>  onClickMyAccount("visitor")}>
                      <div>我的帳戶 My Account</div>
                      <div className="home-button-container-hint">登入 Login</div>
                    </button>
                  </div>
                </Col>
                <Col xs={0} sm={0} md={0} lg={2}>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      );
    }
  }
};
