import React, { useState } from 'react';
import { useParams } from "react-router-dom";

import { ImageBackground , Dimensions, SafeAreaView, ScrollView, StatusBar ,StyleSheet} from "react-native";
import { Paper, stackLayout,List, Grid } from '@mui/material';

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import dayjs from 'dayjs';

import { Card,CardActionArea,CardContent,CardMedia,Typography } from '@mui/material';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import i18n from '../../i18n';

import axios from "../../axios.js";
import TokenService from '../../TokenService.js';

import { useTranslation } from "react-i18next";

import './Profile.css';
import Logo from '../../logo.png';
import SampleQRcode from '../../img/qrcode_coast.png';
import LogoBackground from '../../img/Watermark_Square.png';
import LogoRow from '../../components/logoRow.js';

import TokenIcon from '../../img/icon-DancingTicket.png';

export default function PublicProfile() {
  const { t } = useTranslation();
  const isTraditionalChinese = i18n.language == 'zh_hk';
  const token = TokenService.getToken();
  const [user, setUser] = useState();
  const { uid } = useParams();
  //--
  React.useEffect(() => {
    axios.post('', {
       "name":"userPublicProfile",
       "param": {
           "uid":uid
       }
    })
    .then((response) => {
      console.log(response);
      if(response.status == 200){
        if(response.data.status == 200){
          console.log("userPublicProfile");
          console.log(response.data);
          console.log("-------------");
          setUser(response.data.user);
        }
      }else{
        alert((i18n.language == 'zh_hk')?"錯誤":"错误");
      }
    });
  }, []);
  if(user){
    return(
      <div className="app-wrapper">
        <div className="app-page">
          <Container>
            <Row>
              <LogoRow />
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={2}>
              </Col>
              <Col xs={12} sm={12} md={12} lg={8}>
                <div className="app-page-top">
                  <div className="app-top">
                    <button type="submit" className="app-top-header-button">會員資訊 Member Info</button>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={2}>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={2}>
              </Col>
              <Col xs={12} sm={12} md={12} lg={8}>
                <Card
                  style={{
                    marginTop: "20px",
                    marginBottom: "20px",
                    width: '100%',
                    borderRadius: '28px',
                    backgroundColor: '#E6EDF3',
                    backgroundImage: `url(${LogoBackground})`,
                    backgroundPosition:'center'
                  }}
                >
                  <CardContent>
                    <Typography
                      sx={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 1,
                      }}
                      style={{color:"black",fontWeight: "bold", whiteSpace:'pre-wrap'}}
                      variant="body1"
                    >
                      <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontSize: '24px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        marginBottom: '8px'
                      }}>
                        N'SayN's Member
                      </div>
                      <div className="app-profile-text-block">
                        <img className="app-profile-img" src={user.image_url}/>
                      </div>
                      <hr className="bold-horizen-line"/>
                      <div className="app-profile-text-block">
                        <div className="app-profile-text-block-left">
                          {/* <div className="app-profile-text-block-top">
                            剩餘課堂/舞卷
                          </div>
                          <div className="app-profile-text-block-bottom">
                            Remaining Token
                          </div> */}
                        </div>
                        <div className="app-profile-text-block-right" style={{
                          fontSize: '24px',
                          fontWeight: 'bold',
                        }}>
                          {user.remaining_token}
                        </div>
                      </div>
                      <div className="app-profile-text-block">
                        <div className="app-profile-text-block-left">
                          <div className="app-profile-text-block-top">
                            到期日
                          </div>
                          <div className="app-profile-text-block-bottom">
                            Expiration Date
                          </div>
                        </div>
                        <div className="app-profile-text-block-right" style={{
                          fontSize: '24px',
                          fontWeight: 'bold',
                        }}>
                          {(user.latest_record)?user.latest_record.expiry_date.substring(0,10):"-"}
                        </div>
                      </div>
                      <hr className="bold-horizen-line"/>
                      <div className="app-profile-text-block">
                        <div className="app-profile-text-block-left">
                          <div className="app-profile-text-block-top">
                            會員編號
                          </div>
                          <div className="app-profile-text-block-bottom">
                            Member No.
                          </div>
                        </div>
                        <div className="app-profile-text-block-right">
                          {user.user_number}
                        </div>
                      </div>
                      <div className="app-profile-text-block">
                        <div className="app-profile-text-block-left">
                          <div className="app-profile-text-block-top">
                            姓名
                          </div>
                          <div className="app-profile-text-block-bottom">
                            Name
                          </div>
                        </div>
                        <div className="app-profile-text-block-right">
                          {user.last_name}
                        </div>
                      </div>
                      <div className="app-profile-text-block">
                        <div className="app-profile-text-block-left">
                          <div className="app-profile-text-block-top">
                            電話號碼
                          </div>
                          <div className="app-profile-text-block-bottom">
                            Contact No.
                          </div>
                        </div>
                        <div className="app-profile-text-block-right">
                          {user.contact}
                        </div>
                      </div>
                      <div className="app-profile-text-block">
                        <div className="app-profile-text-block-left">
                          <div className="app-profile-text-block-top">
                            電郵地址
                          </div>
                          <div className="app-profile-text-block-bottom">
                            Email
                          </div>
                        </div>
                        <div className="app-profile-text-block-right">
                          {user.email}
                        </div>
                      </div>
                    </Typography>
                  </CardContent>
                </Card>
              </Col>
              <Col xs={12} sm={12} md={12} lg={2}>
              </Col>
            </Row>

            <Row>
              <Col xs={12} sm={12} md={12} lg={2}>
              </Col>
              <Col xs={12} sm={12} md={12} lg={8}>
                <div className="app-page-bottom">
                  <div className="app-profile">
                    <div className="app-profile-container">
                      <button type="submit" className="app-profile-container-back" onClick={event =>  window.location.href='/home'}>返回主頁 Back Home</button>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={2}>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }else{
    return(<h3></h3>);
  }
};
