import React, { useState, createRef } from 'react';
import { useParams } from "react-router-dom";
import { ImageBackground , Dimensions, SafeAreaView, ScrollView, StatusBar ,StyleSheet} from "react-native";
import { Paper, stackLayout,List, Grid } from '@mui/material';

import dayjs from 'dayjs';

import { useScreenshot, createFileName } from "use-react-screenshot";

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

import { Card,CardActionArea,CardContent,CardMedia,Typography } from '@mui/material';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import i18n from '../../i18n';

import axios from "../../axios.js";
import TokenService from '../../TokenService.js';
import LocalStorageTTL from '../../LocalStorageTTL.js';

import { useTranslation } from "react-i18next";
import Logo from '../../logo.png';
import LogoBackground from '../../img/Watermark_Square.png';
import PaymentSuccessImg from '../../img/check-circle.png';
import LogoRow from '../../components/logoRow.js';

export default function ProfileRecordDetails() {
  const { t } = useTranslation();
  const isTraditionalChinese = i18n.language == 'zh_hk';
  const { record_type } = useParams();
  const selectedRecord = JSON.parse(LocalStorageTTL.getWithExpiry('selectedRecord'));
  //--
  console.log("getSessionValue");
  console.log(selectedRecord);
  if(selectedRecord){
    if(record_type=="rental"){
      //--Set day index
      var display_day = "Sun";
      if(selectedRecord.day_index == 1){
        display_day = "Mon";
      }else if (selectedRecord.day_index == 2) {
        display_day = "Tue";
      }else if (selectedRecord.day_index == 3) {
        display_day = "Wed";
      }else if (selectedRecord.day_index == 4) {
        display_day = "Thu";
      }else if (selectedRecord.day_index == 5) {
        display_day = "Fri";
      }else if (selectedRecord.day_index == 6) {
        display_day = "Sat";
      }
      return(
        <div className="app-wrapper">
          <div className="app-page ">
            <Container>
              <Row>
                <LogoRow />
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={2}>
                </Col>
                <Col xs={12} sm={12} md={12} lg={8}>
                  <Card
                    style={{
                      width: '100%',
                      borderRadius: '28px',
                      backgroundColor: '#E6EDF3',
                      backgroundImage: `url(${LogoBackground})`,
                      backgroundPosition:'center'
                    }}
                  >
                    <CardContent>
                      <div className="app-payment-success-container">
                        <div className="app-payment-success-text">
                          租用紀錄
                        </div>
                        <div className="app-payment-success-text">
                          Rental Record
                        </div>
                      </div>
                      <hr/>
                      <Typography
                        sx={{
                          display: '-webkit-box',
                          overflow: 'hidden',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 1,
                        }}
                        style={{color:"black",fontWeight: "bold", whiteSpace:'pre-wrap'}}
                        variant="body1"
                      >
                        <div className="app-profile-text-block">
                          {selectedRecord.starting_date.substring(0,10)}
                        </div>
                        <div className="app-profile-text-block">
                          {display_day} / {selectedRecord.start_time} - {selectedRecord.end_time}
                        </div>
                        <div className="app-profile-text-block">
                          {dayjs().isBefore(dayjs(selectedRecord.ending_date))?("密碼 Password - "+selectedRecord.entry_password):""}
                        </div>
                        <hr/>
                        <div className="app-profile-text-block">
                          付款方法 Payment Method - {selectedRecord.payment_method}
                        </div>
                        <div className="app-profile-text-block">
                          編號 No. {selectedRecord.id}
                        </div>
                        <div className="app-profile-text-block">
                          {(selectedRecord.remarks)?("租用說明 Remarks - "+selectedRecord.remarks):""}
                        </div>
                        <div className="app-profile-text-block">
                          <img className="app-logo-img" src={selectedRecord.image_url}/>
                        </div>
                      </Typography>
                    </CardContent>
                  </Card>
                </Col>
                <Col xs={12} sm={12} md={12} lg={2}>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={2}>
                </Col>
                <Col xs={12} sm={12} md={12} lg={8}>
                  <div className="app-page-bottom">
                    <div className="app-bottom">
                      <div className="app-bottom-container">
                        <button type="button" className="app-bottom-container-back" onClick={event =>  window.location.href=document.referrer}>返回 Back</button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={2}>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      );
    }else if(record_type=="booking"){
      //--Set day index
      var display_day = "Sun";
      if(selectedRecord.day_index == 1){
        display_day = "Mon";
      }else if (selectedRecord.day_index == 2) {
        display_day = "Tue";
      }else if (selectedRecord.day_index == 3) {
        display_day = "Wed";
      }else if (selectedRecord.day_index == 4) {
        display_day = "Thu";
      }else if (selectedRecord.day_index == 5) {
        display_day = "Fri";
      }else if (selectedRecord.day_index == 6) {
        display_day = "Sat";
      }
      return(
        <div className="app-wrapper">
          <div className="app-page ">
            <Container>
              <Row>
                <LogoRow />
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={2}>
                </Col>
                <Col xs={12} sm={12} md={12} lg={8}>
                  <Card
                    style={{
                      width: '100%',
                      borderRadius: '28px',
                      backgroundColor: '#E6EDF3',
                      backgroundImage: `url(${LogoBackground})`,
                      backgroundPosition:'center'
                    }}
                  >
                    <CardContent>
                      <div className="app-payment-success-container">
                        <div className="app-payment-success-text">
                          預約紀錄
                        </div>
                        <div className="app-payment-success-text">
                          Booking Record
                        </div>
                      </div>
                      <hr/>
                      <Typography
                        sx={{
                          display: '-webkit-box',
                          overflow: 'hidden',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 1,
                        }}
                        style={{color:"black",fontWeight: "bold", whiteSpace:'pre-wrap'}}
                        variant="body1"
                      >
                        <div className="app-profile-text-block">
                          {selectedRecord.class_date.substring(0,10)}
                        </div>
                        <hr/>
                        <div className="app-profile-text-block">
                          {display_day} / {selectedRecord.class_date.substring(11,16)} - {selectedRecord.end_time.substring(0,5)}
                        </div>
                        <div className="app-profile-text-block">
                          付款方法 Payment Method - {selectedRecord.payment_method}
                        </div>
                        <div className="app-profile-text-block">
                          編號 No. {selectedRecord.id}
                        </div>
                        <div className="app-profile-text-block">
                          {(selectedRecord.class_details)?("預約說明 Remarks - "+selectedRecord.class_details):""}
                        </div>
                        <div className="app-profile-text-block">
                          <img className="app-logo-img" src={selectedRecord.image_url}/>
                        </div>
                      </Typography>
                    </CardContent>
                  </Card>
                </Col>
                <Col xs={12} sm={12} md={12} lg={2}>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={2}>
                </Col>
                <Col xs={12} sm={12} md={12} lg={8}>
                  <div className="app-page-bottom">
                    <div className="app-bottom">
                      <div className="app-bottom-container">
                        <button type="button" className="app-bottom-container-back" onClick={event =>  window.location.href=document.referrer}>返回 Back</button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={2}>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      );
    }else{
      return(
        <div className="app-wrapper">
          <div className="app-page ">
            <Container>
              <Row>
                <LogoRow />
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={2}>
                </Col>
                <Col xs={12} sm={12} md={12} lg={8}>
                  <Card
                    style={{
                      width: '100%',
                      borderRadius: '28px',
                      backgroundColor: '#E6EDF3',
                      backgroundImage: `url(${LogoBackground})`,
                      backgroundPosition:'center'
                    }}
                  >
                    <CardContent>
                      <div className="app-payment-success-container">
                        <div className="app-payment-success-text">
                          購買紀錄
                        </div>
                        <div className="app-payment-success-text">
                          Purchase Record
                        </div>
                      </div>
                      <hr/>
                      <Typography
                        sx={{
                          display: '-webkit-box',
                          overflow: 'hidden',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 1,
                        }}
                        style={{color:"black",fontWeight: "bold", whiteSpace:'pre-wrap'}}
                        variant="body1"
                      >
                        <div className="app-profile-text-block">
                          {selectedRecord.created_time.substring(0,10)}
                        </div>
                        <hr/>
                        <div className="app-profile-text-block">
                          購買金額 Amount - HKD${selectedRecord.price}
                        </div>
                        <div className="app-profile-text-block">
                          付款方法 Payment Method - {selectedRecord.payment_method}
                        </div>
                        <div className="app-profile-text-block">
                          編號 No. {selectedRecord.id}
                        </div>
                        <div className="app-profile-text-block">
                          購買內容 Content - 套票 Package {selectedRecord.token_num}張
                        </div>
                        <div className="app-profile-text-block">
                          <img className="app-logo-img" src={selectedRecord.image_url}/>
                        </div>
                      </Typography>
                    </CardContent>
                  </Card>
                </Col>
                <Col xs={12} sm={12} md={12} lg={2}>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={2}>
                </Col>
                <Col xs={12} sm={12} md={12} lg={8}>
                  <div className="app-page-bottom">
                    <div className="app-bottom">
                      <div className="app-bottom-container">
                        <button type="button" className="app-bottom-container-back" onClick={event =>  window.location.href=document.referrer}>返回 Back</button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={2}>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      );
    }
  }else{
    return(<h3></h3>);
  }
};
