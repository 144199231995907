import React from 'react'
import Logo from '../logo.png';

const LogoRow = () => {
  return (
    <div className="app-logo">
        <img className="app-logo-img" src={Logo} onClick={event =>  window.location.href='/home'}/>
        <label className="app-logo-text">Professional Training, Branding, Business Consulting</label>
        <a className="app-logo-text" href="mailto:info@nsayn.com.hk">info@nsayn.com.hk </a>
        <label className="app-logo-text"><a className="app-logo-text-link" href="https://wa.me/+85292525890?text=查詢最新課程">Whatsapp: 92525890</a></label>
    </div>
  )
}

export default LogoRow