import React, { useState , useRef, createRef} from 'react';
import { Dimensions } from "react-native";
import { useParams, useNavigate } from "react-router-dom";
import { TextField } from '@mui/material';
import { Box,Paper, stackLayout,List, Grid } from '@mui/material';

import dayjs from 'dayjs';

import { Card,CardActionArea,CardContent,CardMedia,Typography } from '@mui/material';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import i18n from '../../i18n';

import axios from "../../axios.js";
import TokenService from '../../TokenService.js';
import LocalStorageTTL from '../../LocalStorageTTL.js';

import { useTranslation } from "react-i18next";

import './BookingTable.css';
import Logo from '../../logo.png';
import TokenIcon from '../../img/icon-DancingTicket.png';
import LogoRow from '../../components/logoRow.js';

import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid'

export default function BookingTable() {
  const { t } = useTranslation();
  const isTraditionalChinese = i18n.language == 'zh_hk';
  const [classes, setClasses] = useState();
  // Start edited by Cookie on 2024-06-19 0000151 - add filtering classes by tutor down-drop
  const [tutorList, setTutorList] = useState([]);
  // End edited by Cookie on 2024-06-19 0000151 - add filtering classes by tutor down-drop
  //--Clear
  localStorage.removeItem('proceed_link');
  localStorage.removeItem('member_only');
  React.useEffect(() => {
    axios.get('', {
        params: {
          name: 'getAllLessons',
          headers: {
            'Authorization': 'Bearer ' + TokenService.getToken()
          },
        }
      })
    .then((response) => {
      console.log(response);
      if(response.status == 200){
        if(response.data.status == 200){
          console.log("getAllLessons");
          console.log(response.data.classes);
          console.log("-------------");

          // Start edited by Cookie on 2024-06-19 0000151 - add filtering classes by tutor down-drop
          const tempTutors = [];
          // if(response.data.classes!=null){
          //   response.data.classes.forEach((lesson,idx)=>{
          //     var isTutorExist = false;

          //     tempTutors.forEach((tutor)=>{
          //       if(tutor.tutor_id == lesson.extendedProps.tutor_id)
          //         isTutorExist = true;
          //     })

          //     if(!isTutorExist)
          //       tempTutors.push({tutor_id:lesson.extendedProps.tutor_id, tutor_name:lesson.extendedProps.tutorName});
          //   })
          // }

          if(response.data.tutors!=null){
            response.data.tutors.forEach((tutor,idx)=>{

                tempTutors.push(tutor);
            })

          }


          setTutorList(tempTutors);
          // End edited by Cookie on 2024-06-19 issue 0000151 - add filtering classes by tutor down-drop

          const commonProps = {
            startTime: '00:00:00',
            endTime: '00:00:01',
            extendedProps: { eventType: 'holiday' },
          };

          const holidays = [
            { title: '一月一日', startRecur:"2024-01-01 00:00:00", endRecur: "2024-01-01 23:59:59", ...commonProps,},
            { title: '農曆年初一', startRecur:"2024-02-10 00:00:00", endRecur: "2024-02-10 23:59:59", ...commonProps,},
            { title: '農曆年初三', startRecur:"2024-02-12 00:00:00", endRecur: "2024-02-12 23:59:59", ...commonProps,},
            { title: '農曆年初四', startRecur:"2024-02-13 00:00:00", endRecur: "2024-02-13 23:59:59", ...commonProps,},
            { title: '耶穌受難節', startRecur:"2024-03-29 00:00:00", endRecur: "2024-03-29 23:59:59", ...commonProps,},
            { title: '耶穌受難節翌日', startRecur:"2024-03-30 00:00:00", endRecur: "2024-03-30 23:59:59", ...commonProps,},
            { title: '復活節星期一', startRecur:"2024-04-01 00:00:00", endRecur: "2024-04-01 23:59:59", ...commonProps,},
            { title: '清明節', startRecur:"2024-04-04 00:00:00", endRecur: "2024-04-04 23:59:59", ...commonProps,},
            { title: '勞動節', startRecur:"2024-05-01 00:00:00", endRecur: "2024-05-01 23:59:59", ...commonProps,},
            { title: '佛誕', startRecur:"2024-05-15 00:00:00", endRecur: "2024-05-15 23:59:59", ...commonProps,},
            { title: '端午節', startRecur:"2024-06-10 00:00:00", endRecur: "2024-06-10 23:59:59", ...commonProps,},
            { title: '香港特別行政區成立紀念日', startRecur:"2024-07-01 00:00:00", endRecur: "2024-07-01 23:59:59", ...commonProps,},
            { title: '中秋節翌日', startRecur:"2024-09-18 00:00:00", endRecur: "2024-09-18 23:59:59", ...commonProps,},
            { title: '國慶日', startRecur:"2024-10-01 00:00:00", endRecur: "2024-10-01 23:59:59", ...commonProps,},
            { title: '重陽節', startRecur:"2024-10-11 00:00:00", endRecur: "2024-10-11 23:59:59", ...commonProps,},
            { title: '聖誕節', startRecur:"2024-12-25 00:00:00", endRecur: "2024-12-25 23:59:59", ...commonProps,},
            { title: '聖誕節後第一個周日', startRecur:"2024-12-26 00:00:00", endRecur: "2024-12-26 23:59:59", ...commonProps,},
            { title: '一月一日', startRecur:"2025-01-01 00:00:00", endRecur: "2025-01-01 23:59:59", ...commonProps,},
            { title: '農曆年初一', startRecur:"2025-01-29 00:00:00", endRecur: "2025-01-29 23:59:59", ...commonProps,},
            { title: '農曆年初二', startRecur:"2025-01-30 00:00:00", endRecur: "2025-01-30 23:59:59", ...commonProps,},
            { title: '農曆年初三', startRecur:"2025-01-31 00:00:00", endRecur: "2025-01-31 23:59:59", ...commonProps,},
            { title: '清明節', startRecur:"2025-04-04 00:00:00", endRecur: "2025-04-04 23:59:59", ...commonProps,},
            { title: '耶穌受難節', startRecur:"2025-04-18 00:00:00", endRecur: "2025-04-18 23:59:59", ...commonProps,},
            { title: '耶穌受難節翌日', startRecur:"2025-04-19 00:00:00", endRecur: "2025-04-19 23:59:59", ...commonProps,},
            { title: '復活節星期一', startRecur:"2025-04-21 00:00:00", endRecur: "2025-04-21 23:59:59", ...commonProps,},
            { title: '勞動節', startRecur:"2025-05-01 00:00:00", endRecur: "2025-05-01 23:59:59", ...commonProps,},
            { title: '佛誕', startRecur:"2025-05-05 00:00:00", endRecur: "2025-05-05 23:59:59", ...commonProps,},
            { title: '端午節', startRecur:"2025-05-31 00:00:00", endRecur: "2025-05-31 23:59:59", ...commonProps,},
            { title: '香港特別行政區成立紀念日', startRecur:"2025-07-01 00:00:00", endRecur: "2025-07-01 23:59:59", ...commonProps,},
            { title: '國慶日', startRecur:"2025-10-01 00:00:00", endRecur: "2025-10-01 23:59:59", ...commonProps,},
            { title: '中秋節翌日', startRecur:"2025-10-07 00:00:00", endRecur: "2025-10-07 23:59:59", ...commonProps,},
            { title: '重陽節', startRecur:"2025-10-29 00:00:00", endRecur: "2025-10-29 23:59:59", ...commonProps,},
            { title: '聖誕節', startRecur:"2025-12-25 00:00:00", endRecur: "2025-12-25 23:59:59", ...commonProps,},
            { title: '聖誕節後第一個周日', startRecur:"2025-12-26 00:00:00", endRecur: "2025-12-26 23:59:59", ...commonProps,},
          ]

          // Push the new objects into the array
          holidays.forEach((holiday) => {
            response.data.classes.push(holiday);
          });

          // End edited by Cookie on 2024-06-19 issue 0000144 - show public holiday

          setClasses(response.data.classes);
        }
      }else{
        alert((i18n.language == 'zh_hk')?"錯誤":"错误");
      }
    });
  }, []);
  if(classes){
    return(
      <div className="app-wrapper">
        <div className="app-page">
          <Container>
            <Row>
              <LogoRow />
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="app-booking-page-main">
                  <BookingTableBlock classes={classes} tutorList={tutorList} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }else{
    return(<h3></h3>);
  }
};
function BookingTableBlock(props){
  var isToday = require('dayjs/plugin/isToday')
  dayjs.extend(isToday)
  const navigate = useNavigate();
  //--set calendar ref
  const calendarRef = useRef(null);
  //--get screen size
  const window = Dimensions.get("window");
  const screen = Dimensions.get("screen");

  const onChange = ({ window, screen }) => {
    // setDimensions({ window, screen });
    if(window.width>992 && calendarRef.current.getApi()!=null){
      // console.log(calendarRef.current.getApi());
      calendarRef.current.getApi().changeView('timeGridWeek');
    }else{
      // console.log(calendarRef.current.getApi());
      calendarRef.current.getApi().changeView('timeGridThreeDay');
    }
  };
  React.useEffect(() => {
    Dimensions.addEventListener("change", onChange);
  });
  const { t } = useTranslation();
  const token = TokenService.getToken();
  const eventOnClick = (eventInfo, action) => {
    console.log(eventInfo);
    //--
    if(action == "details"){
      LocalStorageTTL.setWithExpiry('proceed_bookTable_initialDate', eventInfo.event.startStr.substring(0,10));
      console.log(eventInfo.event.id)
      console.log(eventInfo.event.startStr)
      if(window.location!=null)
        window.location.href= navigate(('/book/'+eventInfo.event.id)+'/'+eventInfo.event.startStr.substring(0,10)+"/details");
      else 
        navigate(('/book/'+eventInfo.event.id)+'/'+eventInfo.event.startStr.substring(0,10)+"/details");
    }else if (action == "payment") {
      if(!token){
        LocalStorageTTL.setWithExpiry('proceed_link', ('/home/book/'+eventInfo.event.id)+"/"+eventInfo.event.startStr.substring(0,10)+'/payment');
      }else{
        localStorage.removeItem('proceed_link');
      }
      LocalStorageTTL.setWithExpiry('proceed_bookTable_initialDate', eventInfo.event.startStr.substring(0,10));
       if(window.location!=null)
        window.location.href=navigate(('/book/'+eventInfo.event.id)+'/'+eventInfo.event.startStr.substring(0,10)+'/payment');
      else 
        navigate(('/book/'+eventInfo.event.id)+'/'+eventInfo.event.startStr.substring(0,10)+'/payment');
    }
  }
  function countBookedNum(bookings,current_date){
    let count = 0;
    bookings.forEach(function (booking) {
      if(booking.status!="cancel"){
        if(booking.class_date.substring(0,10)==current_date.substring(0,10)){
          count++
        }
      }
    });
    return count
  }
  const renderEventContent = (eventInfo) => {
    // Start edited by Cookie on 2024-06-19 issue 0000144 - show public holiday
    // console.log(eventInfo);
    if(eventInfo.event.extendedProps.eventType=='holiday'){
      console.log('testpoint');
      return (
    <div className="booking-event booking-holiday">
          <div>{eventInfo.event.title}</div>
    </div>
      )

    } else {
    const remaining = eventInfo.event.extendedProps.capacity - countBookedNum(eventInfo.event.extendedProps.bookings, eventInfo.event.startStr);
    // Start edited by Cookie on 2024-06-19 0000151 - add filtering classes by tutor down-drop
      // console.log('tutor_id:' + eventInfo.event.extendedProps.tutor_id)
      // console.log('selectedTutor:' + selectedTutor)

    if(eventInfo.event.extendedProps.tutor_id == selectedTutor || selectedTutor == '')
      // End edited by Cookie on 2024-06-19 0000151 - add filtering classes by tutor down-drop

    // Start edited by Cookie on 2024-07-15 - Add lesson duration display
    {const startTime = dayjs(eventInfo.event.startStr);
    const endTime = dayjs(eventInfo.event.endStr);
    // const duration = endTime.diff(startTime, 'hour');
    const durationMinutes = endTime.diff(startTime, 'minutes');
    const durationHours = Math.floor(durationMinutes / 60);
    const remainingMinutes = durationMinutes % 60;

      return (
              <div className="booking-event booking-item">
                <div><i>{eventInfo.timeText}</i></div>
                <div>
                  <i>
                    {durationHours > 0 && `${durationHours} 小時 `}
                    {remainingMinutes > 0 && `${remainingMinutes} 分鐘`}
                  </i>
                </div>
                <hr className="bold-horizen-line"/>
                <div><i>{eventInfo.event.title}</i></div>
                <div><i>{eventInfo.event.extendedProps.tutorName}</i></div>
                {
                  dayjs().isBefore(dayjs(eventInfo.event.startStr.substring(0,10)+" "+eventInfo.event.extendedProps.end_time.substring(0,5)))?
                    <>
                    <div><i>{remaining<1?"人數已滿":"剩"+remaining+"位"}</i></div>
                    <button type="submit" className="btn-booking-event btn-booking-event-details" onClick={() => eventOnClick(eventInfo,"details")}>詳情</button>
                    <button type="submit" className="btn-booking-event btn-booking-event-booking" onClick={() => eventOnClick(eventInfo,"payment")} disabled={remaining<1?"disabled":""}>預約</button>
                    </>
                    :
                    <>
                    <button type="submit" className="btn-booking-event btn-booking-event-details" onClick={() => eventOnClick(eventInfo,"details")}>詳情</button>
                    </>
                }
              </div>
      )
    // End edited by Cookie on 2024-07-15 - Add lesson duration display

    }
      else
        return(
          <></>
        )
    }
  };
  // End edited by Cookie on 2024-06-19 issue 0000144 - show public holiday

  // Start edited by Cookie on 2024-06-19 0000151 - add filtering classes by tutor down-drop
  const [selectedTutor, setSelectedTutor] = useState('');

  const handleTutorChange = (event) => {
    setSelectedTutor(event.target.value);
  };
  // End edited by Cookie on 2024-06-19 0000151 - add filtering classes by tutor down-drop
  const initialDate = LocalStorageTTL.getWithExpiry('proceed_bookTable_initialDate')==""?dayjs().format('YYYY-MM-DD'):LocalStorageTTL.getWithExpiry('proceed_bookTable_initialDate');
  if(props){
    console.log(props);
    if(props.classes !== undefined){
      return(
        // Start edited by Cookie on 2024-06-19 0000151 - add filtering classes by tutor down-drop
        <>
          <span class="filter_container">          
            {props.tutorList.length>0 
              ? (<select class="filter-by-tutor" value={selectedTutor} onChange={handleTutorChange}>
                <option value=''>所有導師</option>
                {props.tutorList.map((tutor,idx)=>{
                  return (<option value={tutor.tutor_id}>{tutor.tutor_name}</option>)
                })}
              </select>) 
              : (<></>)
            }
          </span>

          <FullCalendar
            ref={calendarRef}
            plugins={[ timeGridPlugin ]}
            initialDate={initialDate}
            initialView={(window.width>992)?"timeGridWeek":"timeGridThreeDay"}
            views= {{
              timeGridThreeDay: {
                type: 'timeGrid',
                duration: { days: 3 }
              }
            }}
            allDayText="全日"
            titleFormat={{year: 'numeric', month: 'numeric', day: 'numeric'}}
            scrollTime="10:00:00"
            eventTimeFormat= {{ // like '14:30:00'
              hour: '2-digit',
              minute: '2-digit',
              meridiem: false,
              hour12: false
            }}
            weekends={true}
            customButtons={{
              titleButton: {
                text: "Offline mode",
              },
            }}
            headerToolbar={{
              start: 'prev,next', // will normally be on the left. if RTL, will be on the right
              center: 'title',
              end: 'today', // will normally be on the right. if RTL, will be on the left
            }}
            buttonText={{
              today:    '今天 Today',
              prev: '上一個 Previous',
              next: '下一個 Next'
            }}
            events={props.classes}
            eventContent={renderEventContent}
          />
        </>
      // End edited by Cookie on 2024-06-19 0000151 - add filtering classes by tutor down-drop
      );
    }else{
      return(<h3></h3>);
    }
  }else{
    return(<h3></h3>);
  }
}
