import React, { useState } from 'react';
import { ImageBackground , Dimensions, SafeAreaView, ScrollView, StatusBar ,StyleSheet} from "react-native";
import { Box,Paper, stackLayout,List, Grid } from '@mui/material';



import { Card,CardActionArea,CardContent,CardMedia,Typography } from '@mui/material';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import i18n from '../../i18n';

import axios from "../../axios.js";
import TokenService from '../../TokenService.js';

import { useTranslation } from "react-i18next";

import './Rental.css';
import Logo from '../../logo.png';
import LogoRow from '../../components/logoRow.js';


export default function Rental() {
  const { t } = useTranslation();
  const isTraditionalChinese = i18n.language == 'zh_hk';
  const [rooms, setRooms] = useState();

  const styles = StyleSheet.create({
    container: {
      height: '600px'
    },
    scrollView: {
      margin: '20px',
    }
  });
  //--
  React.useEffect(() => {
    axios.get('', {
        params: {
          name: 'getStudioRooms',
          headers: {
            // 'Authorization': 'Bearer ' + TokenService.getToken()
          },
        }
      })
    .then((response) => {
      console.log(response);
      if(response.status == 200){
        if(response.data.status == 200){
          console.log("getStudioRooms");
          console.log(response.data.studio);
          console.log("-------------");
          setRooms(response.data.studio.rooms);
        }
      }else{
        alert((i18n.language == 'zh_hk')?"錯誤":"错误");
      }
    });
  }, []);
  if(rooms){
    return(
      <div className="app-wrapper">
        <div className="app-page">
          <Container>
            <Row>
              <LogoRow />
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={4}>
              </Col>
              <Col xs={12} sm={12} md={12} lg={4}>
                <div className="app-page-topic">
                  <p>選擇場地</p>
                  <p>Choose Rentals</p>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={4}>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={2}>
              </Col>
              <Col xs={12} sm={12} md={12} lg={8}>
                <SafeAreaView style={styles.container}>
                  <ScrollView style={styles.scrollView} vertical={true}>
                    <Grid container rowSpacing={1} columnSpacing={1}>
                      <RentalRoomBlock rooms={rooms} />
                    </Grid>
                  </ScrollView>
                </SafeAreaView>
              </Col>
              <Col xs={12} sm={12} md={12} lg={2}>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }else{
    return(<h3></h3>);
  }
};
function RentalRoomBlock(props){
  const display = (props) => {
      const { rooms } = props;
      console.log(rooms);
      if(rooms !== undefined){
        return(
          <>
            {
              rooms.map( (item, i) => <RentalRoomItem key={i} item={item} /> )
            }
          </>
        );
      }else{
        return(<h3></h3>);
      }
    }
    return(
      <>
        {display(props)}
      </>
    );
}
function RentalRoomItem(props)
{
  const { item } = props;
  return (
    <Grid item xs={12} sm={6} md={6} lg={6}>
      <Card
        style={{
          borderRadius: '28px',
          border:'1px solid',
          backgroundColor: '#E6EDF3',
        }}
        onClick={event =>  window.location.href=roomURL(item.id)}
      >
        <CardActionArea>
          <CardMedia
            component="img"
            src={item.room_image_url}
            title={item.room_name}
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              objectFit: 'fill',
              maxHeight: '188px'
            }}
          />
          <CardContent>
            <Typography
              sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1,
              }}
              style={{color:"#181A20",fontWeight: "bold", whiteSpace:'pre-wrap'}}
              variant="body1"
            >
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                fontSize: '16px',
                fontWeight: 'bold',
                textAlign: 'center',
              }}>
                {item.room_name}
              </div>
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                fontSize: '16px',
                fontWeight: 'bold',
                textAlign: 'center',
              }}>
                {item.room_info}
              </div>
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  )
}
function roomURL(id){
  return("/home/rent/"+id+"");
}
